import { Card, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react'
import ReactPlayer from 'react-player'
const useStyles = makeStyles({

    card: {
        padding: "20px",
        borderRadius: "10px",
        width: "100%",
        backgroundColor: "#F7F7FF"
    },
    heading: {
        fontWeight: '500',
        color: "#000",
        fontSize:"18px"
    },

    video: {
        width: "100%",
        height: "300px"
    },
    noLesson:{

    }

});
export default function CalssComponent(props) {
    const classes = useStyles(props)

    return (
        <Grid container>
            {
                Object.keys(props.selectedLesson).length > 0 ?

                    <Card className={classes.card} >
                        <Grid container spacing={2}>
                            <Grid container item>
                                <ReactPlayer
                                    controls
                                    url={props.selectedLesson.lesson_video_path}
                                    // className={classes.video}
                                    height="290px"
                                />
                                {/* <video
                                    src={props.selectedLesson.lesson_video_path}
                                    // type="video/mp4"
                                    className={classes.video}
                                /> */}
                            </Grid>
                            <Grid container item className={classes.heading}>
                                {props.selectedLesson.lesson_heading}
                            </Grid>
                            <Grid container item>
                                {props.selectedLesson.lesson_description}
                            </Grid>
                        </Grid>
                    </Card>
                    :
                    <h5 container className={classes.noLesson}>
                        No Lesson Found
                    </h5>
            }
        </Grid>
    )
}
