import { Card, CircularProgress, Grid, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
const useStyles = makeStyles({
    topContainer: {
        height: "150px",
        backgroundColor: '#eeee',
        padding: "20px",
        alignItems: "flex-start"
    },
    bottomContainer: {

        padding: "20px"
    },
    card: {
        borderRadius: "10px",
        width: "100%"
    },
    dontrecieve:{
        color:"blue",
        cursor:"pointer"
    }
});
export default function OtpPage(props) {
    const classes = useStyles(props)
    // const [loading, setLoading] = useState(false)

    const [otp, setOtp] = useState("")
    useEffect(() => {
        if (otp.length === 6) {
            props.ValidateOtp(otp)
            // setLoading(true)
        }
    }, [otp])
    const handleOtp = (value) => {
        if (otp.length < 6) {
            setOtp(value)
        }
    }
    return (
        <Card className={classes.card}>
            <Grid container className={classes.topContainer} >
                <Grid item container></Grid>
                <Grid item container></Grid>
                VERIFY DETAILS
                <Grid container  >
                    OTP sent to {props.phone}
                </Grid>
            </Grid>
            <Grid container className={classes.bottomContainer} spacing={2} >
                <Grid item container>
                    ENTER OTP
                </Grid>
                <Grid item container>
                    <TextField
                        value={otp}
                        onChange={(e) => handleOtp(e.target.value)}
                        placeholder="OTP"
                        fullWidth
                    />
                </Grid>
                <Grid item container justifyContent="center" className={classes.dontrecieve} >
                    Did not recieve an OTP?
                </Grid>
                <Grid item container justifyContent="center" >
                    {
                        props.validatingOtp && <CircularProgress />
                    }
                </Grid>

            </Grid>



        </Card>
    )
}
