import { CircularProgress,Grid,Card } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import serverCall from '../Util/serverCall'
const useStyles = makeStyles({
    root: {
        padding: "50px"
    },
    card: {
        padding: "20px",
        borderRadius: "10px",
        paddingTop: "50px",
        position: "relative",
        backgroundColor: "#eee",
        width: "400px"
    },
    heading: {
        fontWeight: 'bold'
    },
    fromCard: {
        padding: "10px",
        borderRadius: "25px",
        position: "absolute",
        top: "-25px",
        zIndex: 999,
        left: "30%",
        cursor:"pointer"
    },
    mainCard:{
        padding:"20px",
        width:"100%",
        // backgroundColor:"#eee",
        // borderBottom:"4px solid blueviolet",
        borderRadius:"10px"
    },
    heading:{
        fontWeight:"bold",
        fontSize:"20px"
    }
    

});
export default function MessageFromTutor(props) {
    const classes = useStyles(props)
    const [messagesData, setMessagesData] = useState({})
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        serverCall({
            method: 'GET',
            url: '/api/tutormessages',
        })
            .then(res => {
                setLoading(false)
                if (res.data) {
                    setLoading(false)
                    console.log("messs",res.data);
                    if(res.data.data){
                        setMessagesData(res.data.data)
                    }
                }
            })
            .catch(err => {
                setLoading(false)
            })
    }, [])
    return (
        <Grid container className={classes.root} justifyContent="center"  >
        <Grid item lg={4} md={6}>
        <Card className={classes.mainCard}>
            {
                loading ? <CircularProgress />
                :
                <>
                
                    {Object.keys(messagesData).length>0  && messagesData.tutor_messages && messagesData.tutor_messages.length>0 ? <>hai</> :  <>No Messages Found</>}
                </>
            }
            </Card >
    </Grid>
        </Grid>
    )
}
