import { Button, Card, CircularProgress, Grid, Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Lessons from '../Lessons';
import Translate from '../Translate';
import serverCall from '../Util/serverCall'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: "50px",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: '300',
        // [theme.breakpoints.down('md')]: {
        //     backgroundColor: 'red',
        // },
    },
    blueContainer: {
        backgroundColor: "#2698C9",
        height: "10px"
    },
    card: {
        borderRadius: "10px",
        width: "100%",
        overflow: "hidden",
        // backgroundColor: "#F7F7FF"
    },
    subContainer: {
        padding: "20px",
    },
    heading: {
        fontWeight: '500',
        color: "#000",
        fontSize: "18px"
    },
    category: {
        fontSize: "13px",
        fontWeight: "300"
    }

}));
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));
function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <BorderLinearProgress variant="determinate"  {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}
                </Typography>
            </Box>
        </Box>
    );
}
export default function Dashboard() {
    const classes = useStyles()
    const [counts, setCounts] = useState({})
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    useEffect(() => {
        setLoading(true)
        serverCall({
            method: 'GET',
            url: '/api/home',
        })
            .then(res => {
                setLoading(false)
                if (res.data.data) {
                    setCounts(res.data.data)
                }
            })
            .catch(err => {
                setLoading(false)
            })
    }, [])

    return (
        <Grid container>
            <Grid container spacing={2} className={classes.root}>

                {/* <video src="https://mobtechnica.fra1.digitaloceanspaces.com/myna/1621704793451Lesson%201%20new%202.mp4?AWSAccessKeyId=4OG4XOJIEEQX2NXUMDMW&Expires=1635756001&Signature=jFQj8%2FVU8SJSQgrb2s4DrN1SEtk%3D" /> */}
                {
                    loading ? <CircularProgress />
                        :
                        <Grid item container>
                            <Grid container spacing={2}>
                                <Grid item container lg={4} md={4} sm={4}>
                                    <Card className={classes.card} >
                                        {/* <Grid container className={classes.blueContainer} /> */}
                                        <Grid container spacing={1} className={classes.subContainer}>
                                            <Grid item container className={classes.heading}>
                                                Your current score {counts.reading ? counts.reading : 0}%
                                            </Grid>
                                            <Grid item container>
                                                <Grid container
                                                // lg={2} md={3} sm={3} xs={4}
                                                >
                                                    <Grid container className={classes.category}>
                                                        Speak
                                                    </Grid>
                                                    <Grid container
                                                    // lg={10} md={9} sm={9} xs={8}
                                                    >

                                                        <Box sx={{ flexGrow: 1 }}>
                                                            <LinearProgressWithLabel
                                                                variant="determinate"
                                                                value={counts.reading ? counts.reading : 0}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>

                                                <Grid container
                                                //  lg={2} md={3} sm={3} xs={4}
                                                >
                                                    <Grid container className={classes.category}>
                                                        Listen
                                                    </Grid>

                                                    <Grid container
                                                    // lg={10} md={9} sm={9} xs={8}
                                                    >
                                                        <Box sx={{ flexGrow: 1 }}>
                                                            <LinearProgressWithLabel
                                                                variant="determinate"
                                                                value={counts.listening ? counts.listening : 0}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>

                                                <Grid container
                                                // lg={2} md={3} sm={3} xs={4}
                                                >
                                                    <Grid container className={classes.category}>
                                                        Type
                                                    </Grid>

                                                    <Grid container
                                                    // lg={10} md={9} sm={9} xs={8}
                                                    >
                                                        <Box sx={{ flexGrow: 1 }}>
                                                            <LinearProgressWithLabel
                                                                variant="determinate"
                                                                value={counts.writing ? counts.writing : 0}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item container className={classes.category}>
                                                Keep Practicing, Great work takes time!
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item container lg={4} md={4} sm={4}>
                                    <Card className={classes.card} >
                                        {/* <Grid container className={classes.blueContainer} /> */}
                                        <Grid container spacing={1} className={classes.subContainer} >
                                            <Grid item container className={classes.heading}>
                                                Message from the Tutor
                                            </Grid>
                                            <Grid item container className={classes.category}>
                                                {!counts.tutor_message ? <>No message from tutor</> : counts.tutor_message.tutor_message}
                                            </Grid>
                                            <Grid item container direction="row-reverse">
                                                <Button onClick={() => history.push('/messages')}  >
                                                    View all
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item container>
                                    <Translate />
                                </Grid>
                                <Grid item container >
                                    <Lessons />
                                </Grid>
                                {/* <Grid item container justifyContent="space-between">
                                <Button onClick={() => history.push('/lessons')}>Lessons</Button>
                                <Button onClick={() => history.push('/translate')}>Translate</Button>
                            </Grid> */}

                            </Grid>
                        </Grid>
                }
            </Grid>
        </Grid>
    )
}
