import { Card, CircularProgress, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import Payment from '../Payment';
import serverCall from '../Util/serverCall'
import LessonDetails from './LessonDetails'
import LockIcon from '@mui/icons-material/Lock';
const useStyles = makeStyles({
    root: {
        // padding: "50px"
        height: "500px",
        overflowY: "scroll",
        fontFamily: "'Roboto', sans-serif",
    },
    card: {
        padding: "20px",
        borderRadius: "10px",
        width: "100%",
        cursor: "pointer",

    },
    mainCard: {
        padding: "20px",
        borderRadius: "10px",
        width: "100%",
    },
    heading: {
        fontWeight: '500',
        color: "#000",
        fontSize: "18px",
        marginBottom: "5px"
    },
    fromCard: {
        padding: "10px",
        borderRadius: "25px",
        position: "absolute",
        top: "-25px",
        zIndex: 999,
        left: "30%",
        cursor: "pointer",

    },
    description: {
        fontSize: "14px"
    },
    noLesson: {
        padding: "10px"
    }


});
export default function Lessons(props) {
    const classes = useStyles(props)

    const [lessons, setLessons] = useState([])
    const [selectedLesson, setSelectedLesson] = useState(1)

    const [loading, setLoading] = useState(false)
    const [hasActivePlan, sethasActivePlan] = useState(true)

    const history = useHistory()
    useEffect(() => {
        setLoading(true)
        serverCall({
            method: 'GET',
            url: '/api/lessonsweb',
        })
            .then(res => {
                setLoading(false)
                if (res.data.data.lessons) {
                    setLessons(res.data.data.lessons)
                }
                if (res.data.message === "You have no active plan") {
                    sethasActivePlan(false)
                }
            })
            .catch(err => {
                setLoading(false)
            })
    }, [])
    const lessonData = (lesson) => <Grid container>
        <Grid container className={classes.heading}>
            {lesson.lesson_heading}
        </Grid>
        <Grid container className={classes.description}>
            {lesson.lesson_description}
        </Grid>
    </Grid>
    return (
        <Grid container spacing={2}>
            {
                !hasActivePlan && <Grid item container>
                    <Card className={classes.mainCard}>
                        <Grid item container style={{ fontWeight: 600 }} >
                            <Grid container spacing={2}>
                                <Grid item container >
                                    You have no active plan to get more lessons upgrade now
                                </Grid>
                                <Grid item container xl={4} lg={4} md={4}>
                                    <Card className={classes.card}>
                                        <Grid container spacing={2}>
                                            <Grid item container>
                                                Rs. 6000
                                            </Grid>
                                            <Grid item container>
                                                3 months
                                            </Grid>
                                            <Grid item container>
                                                <Payment type="basic" />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item container xl={4} lg={4} md={4}>
                                    <Card className={classes.card}>
                                        <Grid container spacing={2}>
                                            <Grid item container>
                                                Rs. 10000
                                            </Grid>
                                            <Grid item container>
                                                6 months
                                            </Grid>
                                            <Grid item container>
                                                <Payment type="medium" />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item container xl={4} lg={4} md={4}>
                                    <Card className={classes.card}>
                                        <Grid container spacing={2}>
                                            <Grid item container>
                                                Rs. 14000
                                            </Grid>
                                            <Grid item container>
                                                12 months
                                            </Grid>
                                            <Grid item container>
                                                <Payment type="high" />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            }
            <Grid item container lg={6} md={6} sm={6} alignItems="flex-start">
                <Card className={classes.mainCard}>
                    {/* <Grid container className={classes.heading} style={{marginBottom:"20px"}}>
                    Lessons
                    </Grid> */}
                    <Grid container spacing={2} className={classes.root} alignItems="flex-start">

                        {
                            loading ? <CircularProgress />
                                :
                                lessons.length === 0 ?
                                    <h5 className={classes.noLesson}>
                                        No Lessons Found
                                    </h5>
                                    :
                                    lessons.map((lesson, index) =>
                                        <Grid item container key={index} >
                                            <Card className={classes.card}
                                                //  onClick={() => history.push(`/lessons/${lesson.lesson_index}`)} 
                                                style={{
                                                    borderLeft: selectedLesson === lesson.lesson_index ? "10px solid #2698C9" : 'none',
                                                    backgroundColor: selectedLesson === lesson.lesson_index ? "#F7F7FF" : 'inherit',
                                                    marginRight: "10px",
                                                    cursor: hasActivePlan ? 'pointer' : index === 0 ? 'pointer' : 'not-allowed'
                                                    // "&:hover":{
                                                    //     backgroundColor:"red",
                                                    //     border:"1px solid red"
                                                    // }
                                                }}
                                                onClick={
                                                    () => hasActivePlan ? setSelectedLesson(lesson.lesson_index)
                                                        : index === 0 ? setSelectedLesson(lesson.lesson_index) : null
                                                }
                                            >
                                                {
                                                    hasActivePlan ? lessonData(lesson)
                                                        : index === 0 ?
                                                            lessonData(lesson)
                                                            : <Grid container>
                                                                <Grid item lg={11} xl={11} md={11} sm={11} xs={11}>
                                                                    {lessonData(lesson)}
                                                                </Grid>
                                                                <Grid item lg={1} xl={1} md={1} sm={1} xs={1}>
                                                                    <LockIcon />
                                                                </Grid>
                                                            </Grid>
                                                }

                                            </Card>
                                        </Grid>
                                    )
                        }

                    </Grid>
                </Card>
            </Grid>
            <Grid item container lg={6} md={6} sm={6}>
                <LessonDetails selectedLesson={selectedLesson} />
            </Grid>
        </Grid>

    )
}
