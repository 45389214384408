import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useHistory } from 'react-router';
import serverCall from '../Util/serverCall';
import Logo from '../Asset/Images/logo1.jpeg'
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(x => ({
    logoText: {
        cursor: "pointer",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: '300',
        '@media (min-width: 400px)': {
            fontSize: "2px"
        },
    
    },
    logo: {
        height: "40px",
        marginRight: "10px",
        marginLeft: "30px",
        cursor: 'pointer'
    }
}))
export default function UserAppBar(props) {
    //   const [auth, setAuth] = React.useState(localStorage.getItem('auth'));
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [user, setUser] = React.useState({});
    const classes = useStyles(props)

    const history = useHistory()
    //   const handleChange = (event) => {
    //     setAuth(event.target.checked);
    //   };
    React.useEffect(() => {
        if (!localStorage.getItem('auth')) {
            history.push('/')
        }
        else {
            setLoading(true)
            serverCall({
                method: 'GET',
                url: '/api/profile',
            })
                .then(res => {
                    setLoading(false)
                    if (res.data.data) {
                        setUser(res.data.data)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }
    }, [])
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.removeItem('auth')
        history.replace('/')
    }

    // React.useEffect(() => {
    //     if (localStorage.getItem('auth')) {
    //         serverCall({
    //             method: 'GET',
    //             url: '/api/user',
    //         }).then(res=>{
    //         }).catch(err=>{
    //             localStorage.removeItem('auth')
    //             history.replace('/')
    //         })
    //     }
    //     else{
    //         history.replace('/')
    //     }
    // }, [])
    return (

        <AppBar position="static" style={{
            // backgroundColor:"#188E8E"
        }}>
            <Toolbar>
                {/* <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton> */}

                <img
                    src={Logo}
                    alt="Sakirmyna"
                    className={classes.logo}
                    onClick={() => history.push("/dashboard")}
                />
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                    className={classes.logoText}
                    onClick={() => history.push("/dashboard")}>
                    Sakirmyna Language Learning
                </Typography>

                <div>
                    <Typography style={{
                        fontSize: "12px",
                        display: "inline"
                    }}>
                        {
                            user.user_name
                        }
                    </Typography>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >

                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => history.push('/profile')}>Profile</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    );
}