// import React from "react";
// import { BrowserRouter as Router, Route } from "react-router-dom";
// import routes from "./routes";
// export default () => (
//   <Router >
//     {
//       routes.map((route, index) => {
//         return (
//           <Route
//             key={index}
//             path={route.path}
//             exact={route.exact}
//             component={(props => {
//               return (
//                 <route.layout {...props}>
//                   <route.component {...props} />
//                 </route.layout>
//               );
//             })}
//           />
//         );
//       })
//     }
//   </Router>
// );

import React from 'react';
import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import Login from './Auth/FirebaseAuth';
import Mainpage from './Auth/Main';
import { BrowserRouter as Router, Route,Redirect } from "react-router-dom";
import routes from "./routes";
function App() {
  const [user] = useAuthState(auth);
  return (
    <Router >
      {
        routes.map((route, index) => {
          return (
            <>
              {/* {user ? <Mainpage /> : <Login />} */}

              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                      </route.layout>
                  );
                })}
              />
              {
                !localStorage.getItem('auth') && <Redirect to="/" />
              }
            </>
          );
        })
      }
    </Router>
    // user ? <Mainpage/> : <Login/>
  );
}

export default App;
