import { Button } from '@mui/material'
import React from 'react'
import Logo from '../Asset/Images/logo1.jpeg'
import serverCall from '../Util/serverCall'

const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src

        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })






    {/* <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
        <script>
        var options = {
        "key": "YOUR_KEY_ID", // Enter the Key ID generated from the Dashboard
        "amount": "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": "INR",
        "name": "Acme Corp",
        "description": "Test Transaction",
        "image": "https://example.com/your_logo",
        "order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "handler": function (response){
            alert(response.razorpay_payment_id);
            alert(response.razorpay_order_id);
            alert(response.razorpay_signature)
        },
        "prefill": {
            "name": "Gaurav Kumar",
            "email": "gaurav.kumar@example.com",
            "contact": "9999999999"
        },
        "notes": {
            "address": "Razorpay Corporate Office"
        },
        "theme": {
            "color": "#3399cc"
        }
        };
        var rzp1 = new Razorpay(options);
        rzp1.on('payment.failed', function (response){
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });
        document.getElementById('rzp-button1').onclick = function(e){
        rzp1.open();
        e.preventDefault();
        }
        </script> */}
}
export default function Payment(props) {
    const _DEV_ = document.domain === 'localhost'
    const displayRazorpay = async () => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
        if (!res) {
            alert("razorpay sdk failed to load")
            return
        }

        const data = await serverCall({
            method: 'POST',
            url: '/api/order',
            data:{
                type:props.type
            }
        })
            .then(res => {
                // setLoading(false)
                // if (res.data.data.lessons) {
                //     setLessons(res.data.data.lessons)
                // }
                console.log(res);
                const options = {
                    "key": _DEV_ ? process.env.RazorpayTest : process.env.RazorpayTest, // Enter the Key ID generated from the Dashboard
                    "amount": res.data.amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                    "currency": res.data.currency,
                    "name": "SakirMyna",
                    "description": "Upgrade",
                    "image": { Logo },
                    "order_id": res.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                    // "handler": function (response) {
                    //     alert(response.razorpay_payment_id);
                    //     alert(response.razorpay_order_id);
                    //     alert(response.razorpay_signature)
                    // },
                    // "prefill": {
                    //     "name": "Gaurav Kumar",
                    //     "email": "gaurav.kumar@example.com",
                    //     "contact": "9999999999"
                    // },
                    "notes": {
                        "address": "Sakirmyna"
                    },
                    "theme": {
                        "color": "#3399cc"
                    }
                };
                var paymentObject = new window.Razorpay(options);
                paymentObject.open()
            })
            .catch(err => {
                // setLoading(false)
                console.log(err);

            })

    }

    return (
        <div>
            {/* <button id="rzp-button1">Pay</button> */}
            <Button variant='outlined'  onClick={displayRazorpay}>Upgrade</Button>
        </div>
    )
}
