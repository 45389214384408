import { Button, Card, CircularProgress, Grid, TextField, Select, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import serverCall from '../Util/serverCall'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
const useStyles = makeStyles({
    root: {
        padding: "50px"
    },
    card: {
        padding: "20px",
        borderRadius: "10px",
        paddingTop: "50px",
        position: "relative",
        backgroundColor: "#eee",
        width: "400px"
    },
    heading: {
        fontWeight: 'bold'
    },
    fromCard: {
        padding: "10px",
        borderRadius: "25px",
        position: "absolute",
        top: "-25px",
        zIndex: 999,
        left: "30%",
        cursor: "pointer"
    },
    mainCard:{
        padding:"20px",
        width:"100%",
        // backgroundColor:"#eee",
        // borderBottom:"4px solid blueviolet",
        borderRadius:"10px"
    },
    heading:{
        fontWeight:"bold",
    }


});
export default function Support(props) {
    const classes = useStyles(props)
    const [callbackreasons, setcallbackreasons] = useState([])
    const [callback_number, setcallback_number] = useState('')
    const [callback_reason, setcallback_reason] = useState({})

    const [saveloading, setsaveLoading] = useState(false)

    const [loading, setLoading] = useState(false)
    const [msg, setmsg] = useState("")

    const history = useHistory()

    const handleUpdate = () => {
        setsaveLoading(true)
        serverCall({
            method: 'POST',
            url: '/api/callbackweb',
            data:{
                callback_reason:callback_reason._id,
                callback_number:callback_number
            }
        })
            .then(res => {
                setsaveLoading(false)
                if(res.data.message === "success"){
                    setmsg("Callback added successfully")
                }
                // if (res.data.data && res.data.data.callback_reasons) {
                //     setcallbackreasons(res.data.data.callback_reasons)
                // }
            })
            .catch(err => {
                setsaveLoading(false)
            })
    }

    useEffect(() => {
        setLoading(true)
        serverCall({
            method: 'GET',
            url: '/api/callbackreasons',
        })
            .then(res => {
                setLoading(false)
                if (res.data.data && res.data.data.callback_reasons) {
                    setcallbackreasons(res.data.data.callback_reasons)
                }
            })
            .catch(err => {
                setLoading(false)
            })
    }, [])

    return (
        <Grid container spacing={2} className={classes.root} justifyContent="center"  >
        <Grid item lg={4} md={6}>
        <Card className={classes.mainCard}>
            {msg}
            {
                loading ? <CircularProgress /> :
                    <Grid  container spacing={2}>
                    <Grid item container className={classes.heading}>
                    Select callback
                    </Grid>
                    <Grid item container>
                    <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            // label="Select reason"

                            fullWidth
                            value={callback_reason}
                            onChange={(e) => setcallback_reason(e.target.value)}

                        >
                            {
                                callbackreasons.map(reason =>
                                    <MenuItem value={reason}>{reason.callback_reason}</MenuItem>
                                )
                            }
                        </Select>
                    </Grid>
                    <Grid item container>
                    <TextField
                            placeholder="Callback number"
                            value={callback_number}
                            onChange={(e) => setcallback_number(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item container>
                    <Button
                            onClick={handleUpdate}
                            disabled={loading || saveloading}
                        >
                            Submit {saveloading && <CircularProgress />}
                        </Button>
                    </Grid>
                        
                        
                        
                        
                    </Grid>
            }
            </Card >
    </Grid>
        </Grid>
    )
}
