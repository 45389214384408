
import { Button, CircularProgress, Grid, Avatar, TextField,Card } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import serverCall from '../Util/serverCall'
const useStyles = makeStyles({
    root: {
        padding: "50px"
    },
    card: {
        padding: "20px",
        borderRadius: "10px",
        paddingTop: "50px",
        position: "relative",
        backgroundColor: "#eee",
        width: "400px"
    },
    heading: {
        fontWeight: 'bold'
    },
    fromCard: {
        padding: "10px",
        borderRadius: "25px",
        position: "absolute",
        top: "-25px",
        zIndex: 999,
        left: "30%",
        cursor: "pointer"
    },
    mainCard:{
        padding:"20px",
        width:"100%",
        // backgroundColor:"#eee",
        // borderBottom:"4px solid blueviolet",
        borderRadius:"10px"
    },
    heading:{
        fontWeight:"bold",
        fontSize:"20px"
    }


});
export default function EditProfile(props) {
    const classes = useStyles(props)
    const [user, setUser] = useState({})
    const [name, setname] = useState("")
    const [mobile, setmobile] = useState("")
    const [email, setemail] = useState("")
    const [profession, setprofession] = useState("")
    const [address, setaddress] = useState("")

    const [saveLoading, setSaveLoading] = useState(false)

    const [loading, setLoading] = useState(false)
    const history = useHistory()
    useEffect(() => {
        
        setLoading(true)
        serverCall({
            method: 'GET',
            url: '/api/profile',
            
        })
            .then(res => {
                setLoading(false)
                if (res.data.data) {
                    setUser(res.data.data)
                }
            })
            .catch(err => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        if (Object.keys(user).length > 0) {
            setname(user.user_name)
            setmobile(user.phone_number)
            setemail(user.email)
            setprofession(user.profession)
            setaddress(user.address)
        }
    }, [user])
    const handleContinue = () => {
        setSaveLoading(true)
        serverCall({
            method: 'PUT',
            url: '/api/profile',
            data:{
                user_name:name,
                phone_number:mobile,
                email,
                address,
                profession
            }
        })
            .then(res => {
                setSaveLoading(false)
                if (res.data.status) {
                    // console.log("updated successfully");
                }
            })
            .catch(err => {
                setSaveLoading(false)
            })
    }
    return (
        <Grid container className={classes.root} justifyContent="center"  >
        <Grid item lg={4} md={6}>
        <Card className={classes.mainCard}>
        {
                loading ? <CircularProgress />
                    :!loading && Object.keys(user).length > 0 ?
                        <Grid container spacing={2}>
                            <Grid item container className={classes.heading}>
                                Edit Profile
                            </Grid>
                            <Grid item container>
                                <TextField fullWidth value={name} placeholder="username" onChange={(e) => setname(e.target.value)} />
                            </Grid>
                            <Grid item container>
                                <TextField fullWidth value={mobile} placeholder="Phone number" onChange={(e) => setmobile(e.target.value)} />

                            </Grid>
                            <Grid item container>
                                <TextField fullWidth value={email} placeholder="Email " onChange={(e) => setemail(e.target.value)} />
                            </Grid>
                            <Grid item container>
                                <TextField fullWidth value={profession} placeholder="profession " onChange={(e) => setprofession(e.target.value)} />
                            </Grid>
                            <Grid item container>
                                <TextField fullWidth rows={3} multiline value={address} placeholder="address " onChange={(e) => setaddress(e.target.value)} />
                            </Grid>
                            <Grid item container direction="row-reverse">
                                <Button
                                    onClick={handleContinue}
                                    disabled={saveLoading}
                                >
                                    Continue {saveLoading && <CircularProgress />}
                                </Button>
                            </Grid>
                        </Grid>
                        : <>something went wrong</>
            }
</Card >
    </Grid>
        </Grid>
    )
}
