import Auth from "./Auth";
import Dashboard from "./Dashboard";
import DefaultLayout from "./Layout/DefaultLayout";
import LoginLayout from "./Layout/LoginLayout";
import Lessons from "./Lessons";
import LessonDetails from "./Lessons/LessonDetails";
import MessageFromTutor from "./MessageFromTutor";
import Payment from "./Payment";
import Profile from "./Profile";
import EditProfile from "./Profile/EditProfile";
import Support from "./Support";
import Translate from "./Translate";

export default [
  {
    path: "/",
    exact: true,
    layout : LoginLayout,
    component:  Auth
  },
  {
    path: "/dashboard",
    exact: true,
    layout : DefaultLayout,
    component:  Dashboard
  },
  {
    path: "/messages",
    exact: true,
    layout : DefaultLayout,
    component:  MessageFromTutor
  },
  {
    path: "/payment",
    exact: true,
    layout : DefaultLayout,
    component:  Payment
  },
  // {
  //   path: "/translate",
  //   exact: true,
  //   layout : DefaultLayout,
  //   component:  Translate
  // },
  // {
  //   path: "/lessons",
  //   exact: true,
  //   layout : DefaultLayout,
  //   component:  Lessons
  // },
  // {
  //   path: "/lessons/:id",
  //   exact: true,
  //   layout : DefaultLayout,
  //   component:  LessonDetails
  // },
  {
    path: "/editProfile",
    exact: true,
    layout : DefaultLayout,
    component:  EditProfile
  },
  {
    path: "/profile",
    exact: true,
    layout : DefaultLayout,
    component:  Profile
  },
  
  {
    path: "/support",
    exact: true,
    layout : DefaultLayout,
    component:  Support
  },
  
];
